import { VueStorefrontModule } from '@vue-storefront/core/lib/module'
import { ShippingModule } from './shipping';
import * as themeConfig from 'theme/config/modules'

// import { DeviceModule } from './device/index';
import { registerModule } from '@vue-storefront/core/lib/modules'

// TODO:distributed across proper pages BEFORE 1.11
export function registerClientModules (fromCache: any = {}) {
  registerModule((ctx) => ShippingModule(ctx, fromCache))
  themeConfig.registerClientModules(fromCache)
}

// Deprecated API, will be removed in 2.0
export const registerModules: VueStorefrontModule[] = [
  ...themeConfig.registerModules
  // Example
]
