import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { BrandStore } from './store'

export const BrandModule: StorefrontModule = function ({ store }, fromCache) {
  if (fromCache?.brand) {
    BrandStore.state = fromCache.brand
  }

  store.registerModule('brand', BrandStore)
}
