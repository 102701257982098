import { ref } from 'vue';
import { generateID } from 'theme/helpers';

const scrollLockBucket = ref([])

export const enableScrollLock = (id: string) => {
  if (scrollLockBucket.value.find((item) => item === id)) return

  scrollLockBucket.value.push(id)

  if (scrollLockBucket.value.length) {
    document.body.classList.add('body-fixed')
  }
}

export const disableScrollLock = (id: string) => {
  scrollLockBucket.value = scrollLockBucket.value.filter((item) => item !== id)

  if (!scrollLockBucket.value.length) {
    document.body.classList.remove('body-fixed')
  }
}

export const useScrollLock = () => {
  const id = generateID()

  return {
    enableScrollLock: () => enableScrollLock(id),
    disableScrollLock: () => disableScrollLock(id)
  }
}
