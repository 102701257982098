import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { CategoryStore } from './store'

export const CategoryModule: StorefrontModule = function ({ store }, fromCache) {
  if (fromCache?.category) {
    CategoryStore.state = fromCache.category
  }

  store.registerModule('category', CategoryStore)
}
