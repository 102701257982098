import { getCurrentInstance, computed } from 'vue'
import { Store } from 'vuex'

export function useStore (): Store<any> {
  const app = getCurrentInstance()
  if (!app) {
    throw new Error('useStore must be called within a component setup function')
  }
  return app.proxy.$store
}

// Хелпери для зручності
export function useStoreHelpers () {
  const store = useStore()

  return {
    // Для state
    useState: <T>(namespace: string, statePath: string) => {
      return computed(() => store.state[namespace][statePath] as T)
    },

    // Для getters
    useGetter: <T>(namespace: string, getter: string) => {
      return computed(() => store.getters[`${namespace}/${getter}`] as T)
    },

    // Для actions
    useAction: (namespace: string, action: string) => {
      return (...args: any[]) => store.dispatch(`${namespace}/${action}`, ...args)
    },

    // Для mutations
    useMutation: (namespace: string, mutation: string) => {
      return (...args: any[]) => store.commit(`${namespace}/${mutation}`, ...args)
    }
  }
}
