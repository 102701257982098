const map = {
  'includeFields': [
    'path',
    'slug',
    'sku',
    'name',
    '_type',
    'url_key',
    'url_path',
    'id',
    'name',
    'content',
    'title',
    'meta_*',
    'active',
    'children_count',
    'children_data.name',
    'children_data.slug',
    'children_data.url_key',
    'children_data.url_path',
    'children_data.id',
    'pim_brand_id',
    'parent_id',
    'is_not_product_category',
    'filterattributes',
    'is_active',
    'redirect_url',
    'position',
    'description',
    'request_path',
    'target_path',
    'redirect_type'
  ],
  'excludeFields': null
}

const smallProduct = {
  'includeFields': [
    'brand_data.name',
    'description',
    'category',
    'category_ids',
    'stock.is_in_stock',
    'forNewPost',
    'stock.qty',
    'stock.max',
    'stock.manage_stock',
    'stock.is_qty_decimal',
    'sku',
    'id',
    'name',
    'image',
    'regular_price',
    'special_price_discount',
    'special_price_to_date',
    'slug',
    'url_key',
    'url_path',
    'product_label',
    'type_id',
    'volume',
    'weight',
    'wghweigh',
    'packingtype',
    'is_new',
    'is_18_plus',
    'news_from_date',
    'news_to_date',
    'varus_perfect',
    'productquantityunit',
    'productquantityunitstep',
    'productminsalablequantity',
    'productquantitysteprecommended',
    'markdown_id',
    'markdown_title',
    'markdown_discount',
    'markdown_description',
    'online_promotion_in_stores',
    'boardProduct'
  ],
  'excludeFields': []
}

const brandList = {
  'includeFields': [
    'item',
    'name',
    'pim_brand_id',
    'slug',
    'url_key',
    'url_path'
  ],
  'excludeFields': []
}

const singleProduct = {
  'includeFields': [
    ...smallProduct.includeFields,
    'parentId',
    'parentSku',
    'description',
    'top_attributes_list',
    'all_attributes_list',
    'info',
    'ratingVivino',
    'brand_data',
    'category_ids',
    'promotion_page_id',
    'stock_shop',
    'promotion_banner_ids',
    'status',
    'stock',
    'visibility',
    'icons_data',
    'property_data',
    'aw_review_rating',
    'aw_review_count',
    'markdown_items_*',
    'markdown_id',
    'markdown_shop',
    'original_product',
    'fv_index',
    'online_promotion_in_stores'
  ],
  'excludeFields': []
}

const categorySearchSmall = {
  'includeFields': [
    'id',
    'name',
    'position',
    'slug',
    'url_key',
    'url_path',
    'level',
    'path',
    'parent_id'
  ],
  'excludeFields': []
}

const cityList = {
  'includeFields': [
    'tms_id',
    'district_polygon'
  ],
  'excludeFields': [
    'city_polygon',
    'district_polygon'
  ]
}

const shopList = {
  'includeFields': [],
  'excludeFields': [
    'phone',
    'is_darkhub',
    'fax',
    'emails',
    'work_time',
    'format_id',
    'format_group_id',
    'region',
    'name'
  ]
}

const smallProductWithCats = {
  'includeFields': [
    ...smallProduct.includeFields,
    'category_ids'
  ],
  'excludeFields': [
    ...smallProduct.excludeFields
  ]
}

const humanAttributeCodes = [
  'attribute_code',
  'uri_name',
  'options.value',
  'options.uri_name'
]

const samplePopular = {
  'categoryIds': [
    53036,
    53051,
    52876,
    52971
  ]
}

const sampleVarusCafe = {
  'categoryIds': [
    52869,
    52875,
    52868,
    56360
  ]
}

export default {
  map,
  smallProduct,
  singleProduct,
  cityList,
  shopList,
  smallProductWithCats,
  humanAttributeCodes,
  brandList,
  samplePopular,
  sampleVarusCafe,
  categorySearchSmall
}
